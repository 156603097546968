import React, { useEffect, useState } from 'react';
import { useCollection } from '../hooks/useCollection';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Card, CardBody, Image, CardFooter, Divider, CardHeader, Spinner, Link, Tooltip, Breadcrumbs, BreadcrumbItem, Accordion, AccordionItem } from '@nextui-org/react';
import { Button } from '@nextui-org/button';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as Copy } from '../images/copy.svg'
import { ReactComponent as DarkCopy } from '../images/darkCopy.svg'
import { ReactComponent as Youtube } from '../images/youtube.svg'
import { ReactComponent as SoundCloud } from '../images/soundcloud.svg'
import { ReactComponent as Documents } from '../images/documents.svg'
import { ReactComponent as DarkDocument } from '../images/darkDocuments.svg'
import { useTheme } from 'next-themes';
import Lottie from 'react-lottie';
import WIP from '../lottie/wip.json';

let pathArray: [{ title: string, path: string }] = [{ title: 'All courses', path: '/courses' }]
let description: [{ title: string, path: string }] = [{ title: '', path: '/courses' }]


const boxAnimation = {
    key: "box",
    initial: {
        y: "50%",
        opacity: 0,
        scale: 0.5,
    },
    animate: {
        y: 0,
        opacity: 1,
        scale: 1,
    },
    exit: {
        y: "50%",
        opacity: 0,
        transition: {
            duration: 0.25,
        },
    },
    transition: {
        duration: 0.25,
        ease: "easeOut",
    },
}
const MainCourses = () => {
    const { theme } = useTheme();
    const { pathname } = useLocation();

    const [descriptionToBeShown, setDescriptionToBeShown] = useState('')

    const { documents: docs, loading } = useCollection(pathname, true, 'order');
    let navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 250)

    }, [docs])

    useEffect(() => {
        // @ts-ignore
        const foundIndex = pathArray.indexOf(pathArray.find((element) => element.path === pathname))
        // @ts-ignore
        const foundIndexForDescription = description.indexOf(description.find((element) => element.path === pathname))
        if (foundIndexForDescription) {
            setTimeout(() => {
                setDescriptionToBeShown(description[foundIndexForDescription]?.title)
            }, 350)

        } else {
            setTimeout(() => {
                setDescriptionToBeShown("")
            }, 350)
        }
        if (foundIndex !== -1) {
            pathArray.splice(foundIndex + 1)
        }
    }, [pathname]);

    if (loading) {
        return <Spinner color='primary' labelColor='primary' className={'absolute top-1/2 left-1/2'} size={'lg'}/>
    }

    const hasChildrenJSX = (doc: any, imageFound: any) => {
        return (
            <motion.div key={doc.id} className={'justify-self-center inline-grid grid-rows-1'}>
                <Card shadow='lg' isPressable={doc.hasChildren} onPress={() => {
                    setTimeout(() => {
                        pathArray.push({ title: doc.name, path: `${pathname}/${doc.id}/children` })
                    }, 250)
                    description.push({ title: doc.description ? doc.description : "", path: `${pathname}/${doc.id}/children` })
                    navigate(`${pathname}/${doc.id}/children`);
                }} className={'group transition duration-150 ease-in-out'}>
                    <CardBody className='overflow-visible p-0'>
                        {imageFound ? <Image
                            isZoomed
                            shadow='sm'
                            radius='lg'
                            width={400}
                            alt={doc.name}
                            className='object-cover h-[425px] lg:h-[460px] w-auto'
                            src={require(`../images/${doc.id}.jpg`)}
                        /> : <Image
                            isZoomed
                            shadow='sm'
                            radius='lg'
                            width={400}
                            alt={doc.name}
                            className='object-cover h-[425px] lg:h-[460px] w-auto'
                            src={require(`../images/imageNotFound.jpeg`)}
                        />
                        }

                    </CardBody>

                    <CardFooter className='justify-between before:bg-lightBlue/10 border-lightGrey/90 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10 bg-black/60 dark:bg-black/60'>
                        <p className='text-lg font-bold text-lightGrey mr-2'>{doc.name}</p>
                        <Button isIconOnly variant='faded' aria-label='Take a photo' className={'w-[40px] h-[40px] rounded-full bg-darkBlue/80 dark:bg-black/70 dark:border-lightGrey text-bold text-lightGrey border-lightGrey/60'}>
                            <b>{doc.order}</b>
                        </Button>
                    </CardFooter>
                </Card>
            </motion.div>
        )
    }

    const noChildrenJSX = (doc: any) => (
        <motion.div>
            <p className={`text-left mb-4 text-darkBlue dark:text-lightGrey font-bold text-xl ml-2.5 ${doc.isPlaylist && 'text-lightOrange font-extrabold'}`}>{doc.name}</p>
            <Card key={doc.id}>
                {doc.description &&
                    <><CardHeader className='flex gap-3'>
                        {doc.description && <div className='flex flex-col'>
                            <p className='text-sm text-turquiose dark:text-lightGrey'>
                                <p className={'text-black mb-1 dark:text-lightGrey'}>Description:</p> {doc.description}
                            </p>
                        </div>}
                    </CardHeader>
                        <Divider/></>
                }
                <CardBody>
                    {doc.soundCloudLink &&
                        <>
                            <div className={'flex justify-evenly mb-2'}>
                                <div className={'ml-0 mr-auto inline-flex'}>
                                    <div className={'mr-2.5 mt-0.5'}>
                                        <SoundCloud/>
                                    </div>
                                    <p>Audio</p>
                                </div>
                                <Tooltip content='Open link in new tab'>
                                    <Link isBlock showAnchorIcon href={`${doc.soundCloudLink}`} color='primary' isExternal/>
                                </Tooltip>
                                <Tooltip content='Copy link'>
                                    <div className={'py-1 px-1 cursor-pointer mt-0.5'} onClick={() => {navigator.clipboard.writeText(doc.soundCloudLink)}}>
                                        {theme === 'light' ? <Copy/> : <DarkCopy/>}
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    }
                    {doc.youtubeLink &&
                        <>
                            {doc.soundCloudLink && <Divider/>}
                            <div className={'flex justify-evenly mb-2 mt-2'}>
                                <div className={'ml-0 mr-auto  inline-flex'}>
                                    <div className={'mr-2.5 mt-[1px]'}>
                                        <Youtube/>
                                    </div>
                                    YouTube
                                </div>
                                <Tooltip content='Open link in new tab'>
                                    <Link isBlock showAnchorIcon href={`${doc.youtubeLink}`} color='primary' isExternal/>
                                </Tooltip>
                                <Tooltip content='Copy link'>
                                    <div className={'py-1 px-1 cursor-pointer mt-0.5'} onClick={() => {navigator.clipboard.writeText(doc.youtubeLink)}}>
                                        {theme === 'light' ? <Copy/> : <DarkCopy/>}
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    }

                    {doc.docsLink &&
                        <>
                            {doc.youtubeLink && <Divider/>}
                            <div className={'flex justify-evenly mb-2 mt-2'}>
                                <div className={'ml-0 mr-auto inline-flex'}>
                                    <div className={'mr-2.5 mt-0.5'}>
                                        {theme === 'light' ? <Documents/> : <DarkDocument/>}

                                    </div>
                                    Class Notes
                                </div>
                                <Tooltip content='Open link in new tab'>
                                    <Link isBlock showAnchorIcon href={`${doc.docsLink}`} color='primary' isExternal/>
                                </Tooltip>
                                <Tooltip content='Copy link'>
                                    <div className={'py-1 px-1 cursor-pointer mt-0.5'} onClick={() => {navigator.clipboard.writeText(doc.docsLink)}}>
                                        {theme === 'light' ? <Copy/> : <DarkCopy/>}
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    }
                </CardBody>
            </Card>
        </motion.div>
    )

    const addLineBreak = (str: string) => {
        return str?.split('\\n').map((subStr) => {
            return (
                <>
                    {subStr}
                    <br/>
                </>
            );
        });
    }

    const itemClasses = {
        base: "py-0 w-full",
        title: "text-xl text-darkBlue font-bold dark:text-lightBlue",
        trigger: "text-red",
        indicator: "text-medium",
        content: "text-bold text-black dark:text-lightGrey",
        subtitle: "text-md",
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: WIP,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <main className='md:mx-24 md:my-10 m-8 pb-14'>
            <AnimatePresence>
                {pathArray.length > 1 && <motion.div {...boxAnimation} className='box'>
                    <div className='flex flex-col flex-wrap gap-4 mb-8 sm:ml-1 ml-0.5 overflow-scroll scrollbar-hide'>
                        {
                            <Breadcrumbs color={`${theme === 'light' ? 'primary' : 'foreground'}`} size={'lg'} separator='/'
                                         itemClasses={{
                                             item: "text-darkBlue dark:text-lightGrey data-[current=true]:text-lightOrange dark:data-[current=true]:text-lightBlue text-xl",
                                             separator: "px-2"
                                         }}
                                         className={`${pathArray.length > 1 ? 'opacity-100' : 'opacity-0'} transition duration-250 ease-in-out`}
                            >
                                {pathArray.map((individualPathDetails: any) => (
                                    <BreadcrumbItem onPress={
                                        () => navigate(individualPathDetails.path)} key={individualPathDetails.path} className={'font-bold text-xl'}>
                                        {individualPathDetails.title}
                                    </BreadcrumbItem>
                                ))}
                            </Breadcrumbs>
                        }
                    </div>
                </motion.div>}
            </AnimatePresence>
            <AnimatePresence>
                {descriptionToBeShown &&
                    <>
                        <motion.div {...boxAnimation} className='box'>
                            <Accordion variant={'splitted'} className={'relative pl-0 before:absolute before:bg-darkBlue   before:top-0 before:bottom-[28.5px] before:left-0 before:border-l-8 before:border-solid before:border-red before:rounded-l-3xl text-red before:opacity-90'}
                                       itemClasses={itemClasses}
                                       motionProps={{
                                           variants: {
                                               enter: {
                                                   y: 0,
                                                   opacity: 1,
                                                   height: "auto",
                                                   transition: {
                                                       height: {
                                                           type: "spring",
                                                           stiffness: 300,
                                                           damping: 30,
                                                           duration: 1,
                                                       },
                                                       opacity: {
                                                           easings: "ease",
                                                           duration: 1,
                                                       },
                                                   },
                                               },
                                               exit: {
                                                   y: -10,
                                                   opacity: 0,
                                                   height: 0,
                                                   transition: {
                                                       height: {
                                                           easings: "ease",
                                                           duration: 0.25,
                                                       },
                                                       opacity: {
                                                           easings: "ease",
                                                           duration: 0.3,
                                                       },
                                                   },
                                               },
                                           },
                                       }}>
                                <AccordionItem
                                    key='2'
                                    aria-label='About this course'
                                    title='About this course'
                                    className={'pl-0 mb-7'}
                                    subtitle={
                                        <p className='text-black dark:text-lightGrey text-md'>
                                            Click to expand/collapse
                                        </p>
                                    }
                                >
                                    <p className={'italic leading-7 text-black dark:text-lightGrey'}>{addLineBreak(descriptionToBeShown)}</p>
                                </AccordionItem>
                            </Accordion>
                        </motion.div>
                        <Divider className={'mb-10'}/>
                    </>
                }
            </AnimatePresence>
            {docs &&
                <>
                    <motion.div className='gap-10 grid xl:grid-cols-4 sm:grid-cols-2 gap-y-16'>
                        {/*@ts-ignore*/}
                        {docs.map((doc, index) => {
                            let imageFound
                            try {
                                imageFound = require(`../images/${doc.id}.jpg`)
                            } catch (e) {
                                imageFound = false
                            }
                            return (
                                <>
                                    {doc.hasChildren &&
                                        <motion.div
                                            key={doc.id}
                                            initial={{
                                                opacity: 0,
                                                translateX: index % 2 === 0 ? -50 : 50,
                                                translateY: -50,
                                            }}
                                            animate={{ opacity: 1, translateX: 0, translateY: 0 }}
                                            transition={{ duration: 0.3, delay: index * 0.2 }}
                                            className={'justify-self-center'}
                                            viewport={{
                                                once: false
                                            }}
                                        >
                                            {hasChildrenJSX(doc, imageFound)}
                                        </motion.div>

                                    }
                                </>
                            )
                        })}
                    </motion.div>
                    <motion.div className='gap-10 grid sm:grid-cols-2 gap-y-16 xs:grid-cols-1'>
                        {/*@ts-ignore*/}
                        {docs.map((doc, index) => {
                            return (
                                <>
                                    {!doc.hasChildren &&
                                        <>
                                            <motion.div
                                                key={doc.id}
                                                initial={{
                                                    opacity: 0,
                                                    translateX: index % 2 === 0 ? -50 : 50,
                                                    translateY: -50,
                                                }}
                                                animate={{ opacity: 1, translateX: 0, translateY: 0 }}
                                                transition={{ duration: 0.3, delay: index * 0.2 }}
                                                className={`${doc.isPlaylist ? 'col-start-1 sm:col-end-3 col-end-2' : 'auto'} `}
                                            >
                                                {noChildrenJSX(doc)}
                                            </motion.div>

                                        </>


                                    }
                                </>
                            )
                        })}
                    </motion.div>
                </>
            }
            {/* @ts-ignore*/}
            {docs?.length === 0 && !loading &&
                <>
                    <div className={'hidden md:flex justify-center mt-10'}>
                        <Lottie
                            options={defaultOptions}
                            height={300}
                            width={300}
                            style={{
                                margin: 0
                            }}
                        />
                    </div>
                    <Card className='w-full'>
                        <CardHeader className='flex gap-3 p-5 justify-center'>
                            <div className='flex flex-col'>
                                <p className='text-xl text-center'>
                                    Live {' '}
                                    <RouterLink className='font-extrabold text-darkBlue dark:text-skyblue' to={'/sessions'}>
                                        Zoom Sessions
                                    </RouterLink> are happening now for this section. please join the class or come back later for recorded sessions.
                                </p>
                            </div>
                        </CardHeader>
                    </Card></>
            }
        </main>

    );
};

export default MainCourses;
