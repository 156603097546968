import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardBody, Spacer, Link } from '@nextui-org/react';

const BSP = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 250)

    }, [])
    return (
        <main className='pb-14 sm:pb-0'>
            <div className={'grid-rows-schedule-row grid-cols-schedule-column grid xs:h-full sm:h-screen sm:grid-cols-8 sm:mt-0'}>
                <div className={'col-span-full bg-bsp brightness-50 bg-fixed bg-center xs:h-full bg-cover bg-no-repeat  xl:block hidden opacity-90 row-span-full'}/>
                <motion.div className={'col-start-2 col-end-8 sm:col-start-3 sm:col-end-7 opacity-[97%] xs:row-end-auto row-start-2 sm:row-end-span-2 h-[calc(100%_-_10rem)]'}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.8 }}
                            transition={{
                                duration: 0.5,
                                ease: "easeOut",
                            }}
                >
                    <Card className={'grid grid-cols-8'}>
                        <CardHeader className='pb-0 pt-2 px-4 flex-col items-center col-span-full'>
                            <h1 className='font-extrabold sm:text-3xl text-2xl mb-2 text-red dark:text-lightGrey mt-2'>Balarāma Śakti Dāsa</h1>
                        </CardHeader>
                        <CardBody className={'col-span-full'}>
                            <p>
                                Balarāma Śakti Dāsa is a disciple of <Link
                                isExternal
                                href='https://www.radhanathswami.com/'
                                className={'inline z-50'}
                            >
                                His Holiness Rādhānātha Swāmi Mahārāja
                            </Link> and is a brahmacāri devotee based out of <Link
                                isExternal
                                href='https://iskconchowpatty.com/'
                                className={'inline z-50'}
                            >
                                ISKCON Chowpatty
                            </Link>, Mumbai, India.
                            </p>
                            <Spacer y={4}/>
                            <p>
                                It was while pursuing his PhD at Pennsylvania State University in 2005, that he took up to Kṛṣṇa Consciousness and eventually chose to move to Śrī Śrī Rādhā-Gopināthā temple in 2007 to become a brahmacārī there.
                            </p>
                            <Spacer y={4}/>
                            <p>In 2010 when he moved to <Link
                                isExternal
                                href='https://ecovillage.org.in/'
                                className={'inline z-50'}
                            >
                                Govardhan Eco Village (GEV)
                            </Link> near Mumbai, he was one of the three original faculties during the inception of <Link
                                isExternal
                                href='https://www.vidyapitha.in/'
                                className={'inline z-50'}
                            >
                                Bhaktivedānta Vidyapīṭha
                            </Link> at GEV. He started the daily brahmacārī discussions on scriptures like the Śrīmad Bhagavad-gītā, Śrīmad-Bhāgavatam and the books of our ācāryas. He continued that service until 2021.
                            </p>
                            <Spacer y={4}/>
                            <p>Apart from GEV and <Link
                                isExternal
                                href='https://iskconchowpatty.com/'
                                className={'inline z-50'}
                            >
                                ISKCON Chowpatty
                            </Link> he has taught for <Link
                                isExternal
                                href='https://www.vidyapitha.in/'
                                className={'inline z-50'}
                            >
                                Bhaktivedānta Vidyapīṭha
                            </Link> travelling to places ranging from Kolkata to Kanpur, Vrindavan to Dharwad and Pune, Thane too.
                            </p>
                            <Spacer y={4}/>
                            <p>Currently, to assist his aged parents, he has moved to Chennai. He conducts atleast three online sessions daily during the week where he teaches in an interactive manner from various books of our sampradāya.The recordings of these sessions constitute an integral part of this website. These sessions are well received having benefited a large number of devotees across the world.</p>
                            <Spacer y={4}/>
                            <p>Since moving to Chennai he has been shuttling between <Link
                                isExternal
                                href='https://iskconchowpatty.com/'
                                className={'inline z-50'}
                            >
                                ISKCON Chowpatty
                            </Link> and Chennai to manage both responsibilities. While in Mumbai, he conducts various in-person sessions for the temple brahmacārīs, doctors at the Bhaktivedānta Hospital, devotee youths, Mira-road congregation members, counselors and mentors of the Śrī Śrī Rādhā-Gopināth temple congregation.
                            </p>
                            <Spacer y={4}/>
                            <p>He has a deep penchant for studying and sharing Vaiṣṇava scriptures.</p>
                            <Spacer y={4}/>
                        </CardBody>
                    </Card>
                </motion.div>
            </div>
        </main>
    )
};

export default BSP;
